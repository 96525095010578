<template>
  <div class="login bg-[#4eac6d] w-full h-screen flex">
    <div class="w-[30%] p-16">
      <div class="flex items-center">
        <div class="w-10 mr-3">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30" fill="#FFFFFF">
            <path d="M15 3C13.307 3 11.817109 3.8458125 10.912109 5.1328125C9.3621094 4.8628125 7.712625 5.318625 6.515625 6.515625C5.318625 7.712625 4.8628125 9.3631094 5.1328125 10.912109C3.8458125 11.817109 3 13.307 3 15C3 16.693 3.8458125 18.182891 5.1328125 19.087891C4.8628125 20.637891 5.318625 22.287375 6.515625 23.484375C7.712625 24.681375 9.3631094 25.137187 10.912109 24.867188C11.817109 26.154187 13.307 27 15 27C16.693 27 18.182891 26.154188 19.087891 24.867188C20.637891 25.137187 22.287375 24.681375 23.484375 23.484375C24.681375 22.287375 25.137187 20.636891 24.867188 19.087891C26.154187 18.182891 27 16.693 27 15C27 13.307 26.154188 11.817109 24.867188 10.912109C25.137187 9.3621094 24.681375 7.712625 23.484375 6.515625C22.287375 5.318625 20.636891 4.8628125 19.087891 5.1328125C18.182891 3.8458125 16.693 3 15 3 z M 21 10C21.25575 10 21.511531 10.097469 21.707031 10.292969C22.098031 10.683969 22.098031 11.316031 21.707031 11.707031L14.146484 19.267578C13.958484 19.455578 13.704453 19.560547 13.439453 19.560547C13.174453 19.560547 12.919422 19.455578 12.732422 19.267578L9.2792969 15.814453C8.8882969 15.423453 8.8882969 14.791391 9.2792969 14.400391C9.6702969 14.009391 10.302359 14.009391 10.693359 14.400391L13.439453 17.146484L20.292969 10.292969C20.488469 10.097469 20.74425 10 21 10 z" fill="#FFFFFF" />
          </svg>
        </div>
        <h1 class="tracking-[0.2rem] font-semibold text-white">ChatApp</h1>
      </div>
    </div>
    <div class="w-[70%] py-10 pr-10">
      <div class="w-full h-full bg-[#2b2b2b] rounded-md relative flex flex-col items-center justify-center">
        <div class="absolute w-[400px] bottom-6 right-[82%]">
          <img src="../assets/logai.png" alt="">
        </div>
        <div class="mb-4 text-center">
          <h2 class="text-[1.7rem] text-[#adb5bd]">Welcome Back!</h2>
          <p class="text-[#8f9198] text-sm">Sign in to continue to ChatApp.</p>
        </div>
        <form class="flex flex-col items-center mb-8 w-[350px]" @submit.prevent="login">
          <label class="w-full text-left text-[#4eac6d] my-2 text-[0.9rem]">Username</label>
          <input type="text" v-model="formdata.username" required placeholder="Enter Username" class="w-full border-none outline-none bg-[#333] p-[.5rem_1rem] text-sm rounded-md text-[#8f9198]  placeholder:text-[#7d7e83]">
          <div class="flex justify-between w-full">
            <label class="w-full text-left text-[#4eac6d] my-2 text-[0.9rem]">Password</label>
                <router-link to="/" class="w-full text-[#adb5bd] my-2 text-[0.9rem] text-right">Forgot Password?</router-link>
            </div>
            <input type="password" v-model="formdata.password" required placeholder="Enter Password" class="w-full border-none outline-none bg-[#333] p-[.5rem_1rem] text-sm rounded-md text-[#8f9198]  placeholder:text-[#7d7e83]">
            <button type="submit" class="mt-5 bg-[#4eac6d] w-full p-[.5rem_1rem] text-sm text-white rounded-md">Log In</button>
        </form>
        <p class="text-[#8f9198] text-sm">Don't have an account? <router-link to="/register" class="text-[#4eac6d] underline">Register</router-link></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoginView',
  data(){
    return{
      formdata: {
        username : '',
        password: ''
      },
      loggeduser: null
    }
  },
  methods:{
    login(){
      this.$store.dispatch('login', this.formdata)
      .then(response => {
        if (response.data != 'Wrong Password?') {
          localStorage.setItem('activeuser', JSON.stringify(response.data[0]))
          this.loggeduser = JSON.parse(localStorage.getItem('activeuser'))
          this.$notify({
            type: "warn",
            title: 'Redirecting.....'
          })
          // setTimeout(function redir(){
            this.$router.push('/chat');
            window.location.reload()
            // },3000)
          } else{
          this.$notify({
            type: "error",
            title: 'Try Again'
          })
        }
      })
    },
    activeuserr(){
      if(localStorage.getItem('activeuser')){
        this.$store.state.activeuser = JSON.parse(localStorage.getItem('activeuser'))
        this.$router.push('/chat')
      }  else{
        this.$router.push('/')
      }
    }
  },
  computed:{
    active(){
      return this.loggeduser = this.$store.state.activeuser
    }
  },
  mounted(){
    this.activeuserr()
  }
}
</script>
<style lang="scss" scoped>
$darkbg: #2b2b2b;
$color: #8f9198;
</style>