<template>
  <router-view/>
  <notifications position="bottom right" />
</template>

<script>
  export default {
    data(){
      return{
        
      }
    },
    methods:{
      activeuserr(){
        if(localStorage.getItem('activeuser')){
          this.$store.state.activeuser = JSON.parse(localStorage.getItem('activeuser'))
          this.$router.push('/chat')
        }  else{
          this.$router.push('/')
        }
      }
    },
    mounted(){
      this.activeuserr()
    }
  }
</script>

<style lang="scss">
$vgray:  #2c3e50;
$vgreen:  #42b983;

$color: #8f9198;
$darkbg: #2b2b2b;
@import url('https://fonts.cdnfonts.com/css/cerebri-sans');

body{
  font-family: 'Cerebri Sans';
}
// #app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
// }
</style>
