import { createStore } from 'vuex'
import axios from 'axios'
// let appURL = 'http://192.168.1.65';
// let appURL = 'http://localhost';
let appURL = 'https://wechattho.000webhostapp.com/';
export default createStore({
  state: {
    activeuser: null,
    // allusers: null,
    // sentreqs: null,
    // receivedreqs: null,
  },
  getters: {
  },
  mutations: {
    befriends(state, payload) {
      axios
        .post('http://' + appURL + '/ChatApp/src/php/befriends.php', payload)
        .then(response => {
          console.log(response.data)
        })
        // .catch(err => console.log(err))
    },
  },
  actions: {
    register(context, payload) {
      return axios
        .post(appURL + 'register.php', payload)
        // .post('http://' + appURL + '/ChatApp/src/php/register.php', payload)
    },
    login(state,payload) {
      return axios
        .post(appURL + 'login.php', payload)
        // .post('http://' + appURL + '/ChatApp/src/php/login.php', payload)
    },
    addfriend(context,payload) {
      axios
        .post('http://' + appURL + '/ChatApp/src/php/addfriend.php', payload)
        .then(response => console.log(response.data))
        // .catch(err => console.log(err))
    },
    pullusers(context,payload){
      return axios
        .post('http://' + appURL + '/ChatApp/src/php/allusers.php', payload);
    },
    sentreq(context,payload) {
      return axios
        .post('http://' + appURL + '/ChatApp/src/php/sentrequests.php', payload)
    },
    receivedreq(context,payload) {
      return axios
        .post('http://' + appURL + '/ChatApp/src/php/receivedrequests.php', payload)
    },
    befriendss(context, payload) {
      return axios
        .post('http://' + appURL + '/ChatApp/src/php/befriends.php', payload)
    },
    myfriends(context, payload){
      return axios
              .post('http://' + appURL + '/ChatApp/src/php/allfriends.php', payload)
    },
    sendmessage(context, payload){
      return axios
              .post('http://' + appURL + '/ChatApp/src/php/sendmessage.php', payload)
    },
    recentchats(context, payload){
      return axios
              .post('http://' + appURL + '/ChatApp/src/php/recentchats.php', payload)
    },
    allmessages(context, payload){
      return axios
              .post('http://' + appURL + '/ChatApp/src/php/messages.php', payload)
    }
  },
  modules: {
  }
})
