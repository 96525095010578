import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/Login.vue'
import RegisterView from '../views/Register.vue'
import ChatView from '../views/Chat.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      title: 'ChatApp - Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    meta: {
      title: 'ChatApp - Register'
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: ChatView,
    meta: {
      title: 'ChatApp - Chat'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to,from,next) => {
  document.title = `${to.meta.title}`;
  next()
})

export default router
